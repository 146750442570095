<template>
  <div :class="animation" class="bg-white rounded border p-8 w-full max-w-lg">
    <div class="flex row justify-between items-center">
      <h1 class="text-left text-xl font-bold">Where do you practice?</h1>
      <i class="pi pi-question-circle text-gray-400" style="font-size: 1.2rem" v-tooltip.left="'If you are licensed in multiple provinces, pick the province you wish to use Virtual Hallway in.'" />
    </div>
    <Message v-if="isCme(this.actingUser)" :closable="false" class="text-left">If you are a licensed clinician, you can complete this process to book consults through Virtual Hallway</Message>
    <h4 class="text-left text-gray-600 text-sm mb-6"></h4>
    <div class="grid col-auto md:grid-cols-2 gap-3 mb-4">
      <div v-for="(province, index) in allProvinces" :key="index" @click="setProvince(province)" class="select-button-small border-hover--blue">
        <span>{{ province.name }}</span>
      </div>
    </div>
    <div class="flex flex-row justify-left items-center align-center">
      <Button label="Previous" @click="goToPrevious()" iconPos="left" class="p-button-text p-buttom-sm p-button-secondary" icon="pi pi-caret-left" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['loggedInUser'],
  emits: ['goToNext', 'goToPrevious'],

  data() {
    return {
      animation: 'animate-in',
      user: this.loggedInUser,
      provinceOptions: [],
    };
  },
  computed: {
    ...mapGetters(['actingUser', 'isGP', 'isSpecialist', 'isCme', 'allProvinces']),
  },
  methods: {
    ...mapActions(['getAllProvinces', 'updateUserAccountInfo']),
    setProvince(province) {
      this.user.practice_province = province;
      this.user.practice_province_id = province.id;
      this.$emit('goToNext', this.user);
    },
    goToPrevious() {
      this.$emit('goToPrevious', this.user);
    },
  },
  created() {
    this.getAllProvinces()
      .then(() => {
        this.provinceOptions = this.getAllProvinces();
      })
      .catch(() => {});
  },
};
</script>

<style></style>

<template>
  <section v-if="!loggedInUser" style="background: #2d2840">
    <div class="px-6 py-12 text-left text-white md:px-12">
      <div class="container mx-auto xl:px-4" style="max-width: 1120px">
        <div class="grid items-center gap-12 lg:grid-cols-2">
          <div class="mt-0 mb-4" :class="{ 'pt-12': this.isNativePlatform() && isCme(loggedInUser) }">
            <h4 class="mb-4 text-xs font-normal tracking-widest uppercase">The Ben Cookey CME webinars:</h4>
            <h1 class="mb-2 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Watch, earn and learn through free CME webinars</h1>
            <p class="mb-8">Gain CME credits and knowledge from specialists on Virtual Hallway</p>
            <div class="flex flex-row w-full">
              <div class="w-full md:w-52 mr-0 md:mr-8">
                <Button @click="showRegistration = true" class="w-full get-started-button" style="display: block">Watch Now</Button>
              </div>
              <div class="w-52 hidden md:block">
                <Button @click="this.$refs.interestedInPresentingLectureDialog.openDialog()" class="w-full get-started-button" style="display: block">Want to be a speaker?</Button>
              </div>
            </div>
          </div>

          <div class="hidden mb-12 lg:mb-0 lg:block">
            <transition name="slide-up-fade" appear>
              <div v-if="showRegistration" class="max-w-lg animated fade-in-up rounded-2xl p-4 sm:p-10 bg-white text-black">
                <RegistrationForm :loggedInUser="loggedInUser" :background_role="'cme'" :customHeadline="'Lecture Registration'" :customButtonLabel="'Register'" />
              </div>
            </transition>
            <img v-if="!showRegistration" src="@/assets/Registration-BG-3.webp" class="w-full shadow-lg image-alt-rounded-corners mb-2" alt="" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="loggedInUser" style="background: #2d2840">
    <div class="px-4 pb-5 text-left text-white md:px-12" :class="isNativePlatform() ? 'pt-32' : 'pt-24'">
      <div class="container mx-auto xl:px-4" style="max-width: 1120px">
        <div class="flex flex-row gap-8">
          <div class="mt-0 mb-4">
            <h4 class="mb-4 text-xs font-normal tracking-widest uppercase">The Ben Cookey CME webinars:</h4>
            <h1 class="mb-2 text-2xl font-bold tracking-tight sm:text-3xl xl:text-4xl sm:mb-6">Watch, earn and learn through free CME webinars</h1>
            <h1 class="text-sm text-gray-200">The Dr. Ben Cookey lectures are regular web-based lectures by experts on a variety of clinical topics. Dr. Cookey was known for his excellent rapport with colleagues, and was always willing to provide input on difficult cases. Some would say he was a master of the "Hallway Consult". Lectures with a <i class="pi fas fa-award mx-1" style="font-size: 0.8rem" /> icon provide you with a CME credit, and supporting documentation to download and submit. <TutorialVideoDialog ref="tutorialVid" video="cme_lectures_credits" linkLabel="Click here to watch how this works." /></h1>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="lecture_menu_bar" v-if="loggedInUser" class="flex flex-col items-center bg-white md:px-4 w-full" style="border-bottom: 1px solid #c9c9c9">
    <div class="w-full flex justify-start items-center" style="max-width: 1120px">
      <Menubar :model="lecture_menu_items" class="w-full md:-ml-4">
        <template #item="{ item, props }">
          <a v-ripple class="flex align-items-center" v-bind="props.action">
            <span :class="item.icon" />
            <span class="ml-2">{{ item.label }}</span>
          </a>
        </template>
      </Menubar>
      <div class="items-center justify-center h-full hidden sm:flex">
        <Dropdown placeholder="Filter by Presenter" id="lecture_presenter_filter_dropdown" :options="allPresenterNames" v-model="selectedLecturePresenter" :showClear="false" @change="filterLecturesByPresenter" class="p-inputtext-sm"></Dropdown>
      </div>
      <div class="items-center justify-center h-full sm:flex">
        <Dropdown placeholder="Filter by Specialty" id="lecture_filter_dropdown" :options="allPresenterSpecialties" v-model="selectedLectureSpecialty" :showClear="false" @change="filterLectures" class="p-inputtext-sm"></Dropdown>
      </div>
    </div>
  </section>
  <section id="lecture_search_bar" v-if="loggedInUser" class="flex flex-col items-center bg-white md:px-4 w-full" style="border-bottom: 1px solid #c9c9c9">
    <div class="w-full flex flex-col md:flex-row items-start justify-start md:items-center px-2 py-2" style="max-width: 1120px">
      <IconField iconPosition="left" class="w-full">
        <InputIcon class="pi pi-search"> </InputIcon>
        <InputText v-model="filterValue" placeholder="Search Lectures" class="w-full p-inputtext-sm" style="padding-left: 2.5rem; width: 100%" maxLength="255" @input="filterValueOnChange" />
      </IconField>
    </div>
  </section>

  <div :class="{ 'main-inner-wrap vld-parent': !isNativePlatform(), 'px-5 py-4': isNativePlatform() }">
    <div class="w-full px-0 sm:px-4" style="max-width: 1120px" :class="{ 'mt-4': isNativePlatform() }">
      <div v-if="this.filteredUpcomingLectures.length > 0" class="-mt-4">
        <div class="flex flex-col-reverse justify-between md:flex-row mb-8">
          <div class="flex flex-col items-start w-full text-left">
            <h1 class="mb-6 text-2xl font-bold">Upcoming Lectures</h1>
            <p class="text-sm mb-4 -mt-4">Select a lecture below to learn more, or register to attend the live event.</p>
            <div class="c-divider" style="margin-left: 0"></div>
          </div>
        </div>
        <div v-auto-animate class="grid grid-cols-1 mb-16 lecture-grid justify-items-center md:grid-cols-2" :class="[{ 'lg:grid-cols-2 gap-12': this.upcomingLectures.length < 3 }, { 'lg:grid-cols-3 gap-6': this.upcomingLectures.length >= 3 }]">
          <LectureCard v-for="(lecture, index) in filteredUpcomingLectures" :key="lecture.id" :lecture="lecture" :index="index" buttonText="Register" />
          <div v-if="!isLoading && loggedInUser && this.filteredUpcomingLectures.length < 1" class="relative flex flex-col justify-start items-start w-full border-1">
            <h1 class="mb-2 text-2xl">No Results</h1>
            <Button label="Clear Filters" @click="this.clearFilters()" class="p-button-xs" style="width: 200px" />
          </div>
        </div>
      </div>

      <div class="flex flex-col justify-start w-full text-left sm:flex-row sm:justify-between" ref="onDemandLectures">
        <div class="flex-col justify-start flex-">
          <h1 class="mb-6 text-2xl font-bold">Watch on demand</h1>
          <p class="text-sm mb-4 -mt-4">Select a lecture below to watch the recording, or submit your feedback to receive your free CME credit documentation.</p>
          <div class="mb-8 c-divider" style="margin-left: 0"></div>
        </div>
      </div>

      <div v-auto-animate class="grid grid-cols-1 gap-6 mb-16 lecture-grid justify-items-center md:grid-cols-2 lg:grid-cols-3">
        <LectureCardLoadingSkeleton v-if="isLoading" />
        <LectureCardLoadingSkeleton v-if="isLoading" />
        <LectureCardLoadingSkeleton v-if="isLoading" />
        <LectureCard v-for="(lecture, index) in filteredPastLectures" :key="lecture.id" :lecture="lecture" :index="index" buttonText="Watch Now" />
        <div v-if="!isLoading && loggedInUser && this.filteredPastLectures.length < 1" @click="this.clearFilters()" class="rounded-xl tile relative flex flex-col justify-center items-center w-full border-1 cursor-pointer h-64">
          <h1 class="mb-2 text-2xl">No Results</h1>
          <Button label="Clear Filters" class="p-button-xs" style="width: 200px" />
        </div>
      </div>
      <ArchivedLectures :archivedLectures="this.archivedLectures" />
    </div>
  </div>

  <section id="lecture_value_props" v-if="!loggedInUser" class="bg-white">
    <div class="max-w-screen-xl pb-8 pt-4 mx-auto" style="max-width: 1120px">
      <div class="grid grid-cols-1 gap-8 mt-8 md:grid-cols-2 lg:grid-cols-3">
        <div class="block p-8">
          <i class="mx-auto pi pi-check text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Earn on demand</h2>
          <p class="mt-1 text-sm text-gray-500">Watch a recent webinar and fill out a short quiz to instantly receive CME credits.</p>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-desktop text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Watch live</h2>
          <p class="mt-1 text-sm text-gray-500">Connect with peers and ask questions through live, interactive webinars given by Virtual Hallway specialists.</p>
        </div>
        <div class="block p-8">
          <i class="mx-auto pi pi-comments text-primary" style="font-size: 2rem"></i>
          <h2 class="mt-4 text-xl font-bold text-primary">Consult with speakers</h2>
          <p class="mt-1 text-sm text-gray-500">Have a question about a patient you want to ask the speaker? Consult with them after the lecture on Virtual Hallway.</p>
        </div>
      </div>
    </div>
  </section>
  <InterestedInPresentingLectureDialog ref="interestedInPresentingLectureDialog" />
  <RequestCmeTopicDialog ref="requestCmeTopicDialog" />
  <Loading z-index="99" v-model:active="isLoading" color="#ef5164" :can-cancel="false" :is-full-page="false" />
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Loading from 'vue-loading-overlay';
import LectureCard from '@/components/cme/LectureCard.vue';
import TutorialVideoDialog from '@/components/misc/TutorialVideoDialog.vue';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import moment from 'moment';
import RequestCmeTopicDialog from '@/components/cme/RequestCmeTopicDialog.vue';
import LectureCardLoadingSkeleton from '@/components/cme/LectureCardLoadingSkeleton.vue';
import RegistrationForm from '@/views/public/registration/registrationForm/RegistrationForm.vue';
import OAuth from '@/views/public/OAuth.vue';

import { mapGetters, mapActions } from 'vuex';
export default {
  components: {
    OAuth,
    Button,
    Loading,
    Dialog,
    LectureCard,
    Dropdown,
    InputText,
    TutorialVideoDialog,
    RequestCmeTopicDialog,
    LectureCardLoadingSkeleton,
    RegistrationForm,
  },

  data() {
    return {
      showRegistration: false,
      lecture_menu_items: [
        {
          label: 'My Credits',
          icon: 'fas fa-award',
          command: () => {
            this.$router.push('/lecturecredits/');
          },
        },
        {
          label: 'Become a Speaker',
          icon: 'pi pi-fw pi-user-plus',
          command: () => {
            this.$refs.interestedInPresentingLectureDialog.openDialog();
          },
        },
        {
          label: 'Request a Lecture',
          icon: 'pi pi-send',
          command: () => {
            this.$refs.requestCmeTopicDialog.openDialog();
          },
        },
        {
          label: 'Popular',
          icon: 'pi pi-fw pi-star',
          command: () => {
            this.scrollToOnDemandAndSort();
          },
        },
        // {
        //   label: 'Bookmarked',
        //   icon: 'pi pi-bookmark',
        //   command: () => {
        //     this.toggleFilterLecturesByIsBookmarked();
        //   },
        // },
        {
          label: 'Help',
          icon: 'pi pi-fw pi-question-circle',
          command: () => {
            this.$store.commit('setOpenedSupportSectionAccountAccordionIndex', 6);
          },
        },
      ],
      filterValue: '',
      filteredUpcomingLectures: [],
      filteredPastLectures: [],
      isLoading: false,
      selectedLectureSpecialty: null,
      selectedLecturePresenter: null,
      toggleIsBookmarkedFilter: false,
      allPresenterNames: [],
      allPresenterSpecialties: [],
    };
  },
  computed: {
    ...mapGetters(['lectures', 'loggedInUser', 'isLoggedIn', 'isGP', 'lectureCredits', 'isCme']),
    upcomingLectures() {
      return this.lectures
        .slice()
        .reverse()
        .filter((lecture) => {
          return moment.utc(lecture.lecture_datetime).local().isAfter(moment.utc().subtract(60, 'minutes').local());
        });
    },
    pastLectures() {
      let oneYearAgo = moment().subtract(1, 'years');
      return this.lectures.filter((lecture) => {
        return moment.utc(lecture.lecture_datetime).local().isBetween(oneYearAgo, moment());
      });
    },
    archivedLectures() {
      return this.lectures.filter((lecture) => {
        return moment.utc(lecture.lecture_datetime).local().isBefore(moment().subtract(1, 'years').local());
      });
    },
  },
  methods: {
    ...mapActions(['getAllLectures', 'addNewLecture', 'submitInterestedInSpeaking', 'submitRequestCmeTopic', 'getAllLectureCreditsOfUser']),
    checkIfUserHasClaimedCredit(lecture_card_id) {
      return this.lectureCredits.find((lecture_credit) => lecture_credit.id === lecture_card_id);
    },
    filterLectures() {
      if (this.selectedLectureSpecialty === '' || this.selectedLectureSpecialty === null) {
        this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_published); //Redundant because we do this on the backend now
        this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.lecture_published); //Redundant because we do this on the backend now
      } else {
        this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.presenter_specialty.includes(this.selectedLectureSpecialty));
        this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.presenter_specialty.includes(this.selectedLectureSpecialty));
      }
    },
    filterLecturesByPresenter() {
      if (this.selectedLecturePresenter === '' || this.selectedLecturePresenter === null) {
        this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.presenter_name);
        this.filteredUpcomingLectures = this.upcomingLectures;
      } else {
        this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.presenter_name.includes(this.selectedLecturePresenter));
        this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.presenter_name.includes(this.selectedLecturePresenter));
      }
    },
    filterPastLecturesByTotalViews() {
      this.filteredPastLectures = this.pastLectures.sort(({ total_views: a }, { total_views: b }) => b - a);
      this.$forceUpdate();
    },
    toggleFilterLecturesByIsBookmarked() {
      if (this.toggleIsBookmarkedFilter == false) {
        this.toggleIsBookmarkedFilter = true;
        this.filterLecturesByIsBookmarked();
      } else {
        this.toggleIsBookmarkedFilter = false;
        this.filteredPastLectures = this.pastLectures;
        this.filteredUpcomingLectures = this.upcomingLectures;
        this.$forceUpdate();
      }
    },
    filterLecturesByIsBookmarked() {
      this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.bookmarked);
      this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.bookmarked);
      this.$forceUpdate();
    },
    filterValueOnChange() {
      if (this.filterValue === '') {
        this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_published);
        this.filteredUpcomingLectures = this.upcomingLectures;
      } else {
        // this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_title.toLowerCase().includes(this.filterValue.toLowerCase()));
        // this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => lecture.lecture_title.toLowerCase().includes(this.filterValue.toLowerCase()));
        //filters by all values
        // https://stackoverflow.com/questions/45991273/how-to-use-filter-to-search-in-multiple-key-values-of-objects-in-an-array
        this.filteredPastLectures = this.pastLectures.filter((lecture) => Object.values(lecture).some((val) => String(val).toLowerCase().includes(this.filterValue.toLowerCase())));
        this.filteredUpcomingLectures = this.upcomingLectures.filter((lecture) => Object.values(lecture).some((val) => String(val).toLowerCase().includes(this.filterValue.toLowerCase())));
      }
    },
    clearFilters() {
      this.filterValue = '';
      this.selectedLectureSpecialty = null;
      this.selectedLecturePresenter = null;
      this.filteredPastLectures = this.pastLectures.filter((lecture) => lecture.lecture_published);
      this.filteredUpcomingLectures = this.upcomingLectures;
    },
    isCurrentLecture(lecture) {
      let lectureEndTime = moment.utc(lecture.lecture_datetime).add(60, 'minutes');
      return moment.utc().isBetween(moment.utc(lecture.lecture_datetime), lectureEndTime);
    },
    scrollToOnDemandAndSort() {
      this.$refs.onDemandLectures.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
      this.filterPastLecturesByTotalViews();
    },
  },
  created() {
    this.isLoading = true;
    this.getAllLectures()
      .then(() => {
        //Adds Dr. before each name
        // this.allPresenterNames = [...new Set(this.lectures.map(({ presenter_name }) => 'Dr. ' + presenter_name).sort((a, b) => a.localeCompare(b)))];
        this.allPresenterNames = [...new Set(this.lectures.map(({ presenter_name }) => presenter_name).sort((a, b) => a.localeCompare(b)))];
        //Splits users into seaparate values
        let x = this.allPresenterNames.reduce(function (acc, curr) {
          acc.push(...curr.split(', '));
          return acc;
        }, []);

        this.allPresenterSpecialties = [...new Set(this.lectures.map(({ presenter_specialty }) => presenter_specialty).sort((a, b) => a.localeCompare(b)))];
        let yx = this.allPresenterSpecialties.reduce(function (acc, curr) {
          acc.push(...curr.split(' & '));
          return acc;
        }, []);

        this.filterLectures();
        this.isLoading = false;
        if (this.loggedInUser) {
          this.getAllLectureCreditsOfUser(this.loggedInUser.id)
            .then(() => (this.isLoading = false))
            .catch(() => {
              this.isLoading = false;
              this.showGenericErrorToast();
            });
        }
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
};
</script>

<style>
.p-menubar-root-list {
  min-width: 230px;
}
.dark-blue {
  color: #5b76a2;
}
.subheading-blue {
  color: #859dc4;
}
.video-background {
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: gray;
}
.cme-label {
  color: white;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}
.cme-live-label {
  color: white;
  background-color: #ef5164;
  top: 0;
  left: 0;
  position: absolute;
  border-top-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
  z-index: 2;
}
.credit-claimed-label {
  color: white;
  bottom: 1.5rem;
  right: 0;
  position: absolute;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.image-alt-rounded-corners {
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
}
.p-menubar {
  background: none;
}
.p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
  padding: 0.5rem 1rem 0.5rem 1rem;
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #394353;
}

.p-menubar .p-menubar-root-list > .p-menuitem:hover {
  background: #f0f2f4;
  border-radius: 5px;
}
.p-menuitem {
  margin-bottom: 0;
}
@media (max-width: 960px) {
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link {
    flex-direction: row-reverse;
    justify-content: start;
  }
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > svg,
  .p-menubar .p-menubar-root-list > .p-menuitem > .p-menuitem-content .p-menuitem-link > .pi {
    margin-left: 0.5rem;
  }
}
#lecture_filter_dropdown,
#lecture_presenter_filter_dropdown {
  border: none;
  font-family: 'Poppins';
  font-size: 0.8rem;
}
#lecture_filter_dropdown > span,
#lecture_presenter_filter_dropdown > span {
  border: none;
  font-weight: 600;
  color: rgb(57, 67, 83);
  font-family: 'Poppins';
  font-size: 0.8rem;
}
#lecture_filter_dropdown:hover,
#lecture_presenter_filter_dropdown:hover {
  background: #f0f2f4;
}
#lecture_filter_dropdown_list > span {
  color: rgb(57, 67, 83);
  font-family: 'Poppins';
}
#lecture_filter_dropdown_list,
#lecture_presenter_filter_dropdown_list {
  font-size: 0.8rem;
  color: rgb(57, 67, 83);
  font-family: 'Poppins';
}

.p-selectbutton .p-togglebutton:first-child {
  border-left-width: 1px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.p-selectbutton .p-togglebutton:last-child {
  border-left-width: 1px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
